import React, { useEffect, useState } from "react";
import RIGHT from "../../asset/right2.png";
import { Helmet } from "react-helmet-async";
// import FIRST from "../../asset/products/section1test.png"
// import FIRST from "../../asset/products/pp1.png"
import FIRST from "../../asset/products/proo.png";
import SECOND from "../../asset/products/section2.png";
import THIRD from "../../asset/products/Section3.png";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function Products() {
  const controls = useAnimation();
  const [txtRef, inTxt] = useInView();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const animationTxt = {
    textHide: { opacity: 0, x: 1000 },
    textShow: { opacity: 1, x: 0, transition: { duration: 1 } },
  };
  const animationAc = {
    textHide: { opacity: 0, y: -3000 },
    textShow: { opacity: 1, y: 0, transition: { duration: 1.5 } },
  };
  const animation1 = {
    textHide: { opacity: 0, x: -3000 },
    textShow: { opacity: 1, x: 0, transition: { duration: 1.5 } },
  };
  const animation2 = {
    textHide: { opacity: 0, x: 3000 },
    textShow: { opacity: 1, x: 0, transition: { duration: 1.5 } },
  };
  const animation3 = {
    textHide: { opacity: 0, x: -3000 },
    textShow: { opacity: 1, x: 0, transition: { duration: 1.5 } },
  };

  useEffect(() => {
    if (inTxt) {
      controls.start("textShow");
    } else {
      controls.start("textHide");
    }
  }, [controls, inTxt]);

  const pageVariants = {
    initial: {
      opacity: 0,
      x: -100,
    },
    in: {
      opacity: 1,
      x: 0,
    },
    out: {
      opacity: 0,
      x: 100,
    },
  };
  const pageVariants2 = {
    initial: {
      opacity: 0,
      x: 100,
    },
    in: {
      opacity: 1,
      x: 0,
    },
    out: {
      opacity: 0,
      x: -100,
    },
  };

  const pageTransition = {
    type: "spring",
    stiffness: 50,
    damping: 20,
  };

  const handleOpen = (newId) => {
    if (newId === id) {
      setOpen(!open);
    } else {
      setId(newId);
      setOpen(true);
    }
  };

  const topArray = [
    {
      id: 0,
      title: "Wallmounted Split Acs",
      dsp: "Our showroom proudly features a wide range of wall-mounted split ACs. We offer models from leading brands such as Haier, Samsung, Blue Star, TCL, Toshiba, LG, Gree, and more, which prioritize energy efficiency, quiet operation, and ease of use, making them ideal for both homes and offices.",
    },
    {
      id: 1,
      title: "Window Acs",
      dsp: " We are also the top Gree AC and Blue star AC dealer in Doha. At our Windows AC showroom in Doha, Qatar, we offer a curated selection of top-performing window air conditioners that combine reliability, efficiency, and affordability. Our range includes models from trusted brands Panasonic AC, LG AC, Gree, General AC etc.",
    },
    {
      id: 2,
      title: "Ductable Split Acs",
      dsp: "Ductable split air conditioners in Doha, Qatar. These are an ideal cooling solution for commercial and residential spaces. With the ability to cool large areas efficiently, these systems are designed to withstand the region's extreme heat while ensuring optimal indoor comfort.Buy Split AC from Top AC shop in Doha for businesses, offices, and luxury homes across Qatar.",
    },
    {
      id: 3,
      title: "Cassette Acs",
      dsp: "Leading Cassette AC showroom in Qatar, we  offers a range of premium air conditioning solutions tailored for both commercial and residential spaces. Cassette ACs are designed to be installed in the ceiling, providing a sleek and unobtrusive cooling solution that evenly distributes air throughout the room.",
    },
    {
      id: 4,
      title: "Floor Standing Acs",
      dsp: "Floor-standing air conditioners are a popular choice in Doha, offering powerful cooling solutions for large spaces such as offices, showrooms, and luxury homes. Showrooms in Doha sell a range of branded floor-standing ACs brands such as LG, Samsung, and Carrier.",
    },
  ];

  const topArray1 = [
    {
      id: 5,
      title: "Heating & Cooling Systems",
      dsp: "Our showroom's showcase top global brands, providing customers with energy-efficient and high-performance solutions for year-round climate control. From HVAC systems and heat pumps to ductless mini-splits and ventilation units, the equipment available caters to Qatar's hot climate.",
    },
    {
      id: 6,
      title: "Water Tank Cooling System",
      dsp: "Our water tank cooling system showrooms in Qatar offer specialized solutions to manage water temperatures in large tanks, ensuring optimal cooling in the region's hot climate. These systems are crucial for industries, residential buildings, and commercial facilities that require consistent and reliable water cooling",
    },
    {
      id: 7,
      title: "Chilled Water Systems",
      dsp: "These systems, which use chilled water to regulate indoor temperatures, are ideal for Qatar's hot climate, offering energy-efficient and effective solutions for buildings of all sizes. The shops feature top brands known for their reliability and performance, with products that include chillers, pumps and air handlers",
    },
    {
      id: 8,
      title: "Energy Recovery Ventilator",
      dsp: "Our shops provide a variety of models from leading brands, catering to both residential and commercial applications. With a focus on energy efficiency and improved air circulation, ERVs help create healthier indoor environments while minimizing energy costs.",
    },
    {
      id: 9,
      title: "Fan Coil Units (FCU)",
      dsp: "Top Fan coil unit showrooms in Qatar offer a wide range of climate control solutions ideal for both residential and commercial spaces. These units, which are essential components of HVAC systems, provide efficient heating and cooling by utilizing water or refrigerant to condition the air.",
    },
    {
      id: 10,
      title: "Fresh Air Handling Units (AHU)",
      dsp: "Our fresh air handling unit showrooms in Qatar showcase top brands that focus on energy efficiency and advanced air filtration technologies, with knowledgeable staff available to assist customers in selecting the best AHU for their specific needs.",
    },
  ];

  const topArray2 = [
    {
      id: 11,
      title: "Air Purifiers",
      dsp: "Our showroom should feature a diverse range of high-quality air purifiers from reputable global brands, covering different price points and capabilities. Emphasizing certifications like HEPA filters, energy efficiency, and smart technology can set the company apart.",
    },
    {
      id: 12,
      title: "Air Curtains",
      dsp: "We offer a wide range of air curtains that cater to the unique needs of retail spaces, restaurants, offices, and industrial facilities. Our products are designed to enhance indoor climate control by reducing energy loss and preventing external contaminants.",
    },
    {
      id: 13,
      title: "Air Coolers",
      dsp: "Our company is a leading provider of top-branded air coolers, offering innovative and energy-efficient cooling solutions for residential, commercial, and industrial spaces. We specialize in air coolers that combine advanced technology with reliable performance.",
    },
    {
      id: 14,
      title: "VRF Systems",
      dsp: "Our Showroom specializes in delivering top-branded VRF (Variable Refrigerant Flow) system air conditioners, offering state-of-the-art climate control solutions for commercial, residential, and industrial applications.",
    },
    {
      id: 15,
      title: "Ventilation Fan",
      dsp: "We prioritize top-branded Ventilation products known for their durability, energy efficiency, and quiet operation. Along with our extensive product range, we offer expert guidance, installation services.",
    },
    {
      id: 16,
      title: "Package Units",
      dsp: "We offer a variety of top-branded units known for their energy efficiency, robust performance, and ease of installation, making them perfect for the region's demanding climate.",
    },
  ];

  return (
    <div
      ref={txtRef}
      className=" bg-black-1 w-full h-auto flex items-center justify-center"
    >
      {/* className='md:bg-green lg:bg-hero 2xl:bg-red xl:bg-event bg-purple w-full h-auto flex items-center justify-center'> */}
      <Helmet>
        <title>Split AC shop in Qatar | Windows AC showroom in Doha</title>
        <meta
          name="description"
          content="AC Qatar is leading Split AC shop in Qatar. Shop windows ac from best ac shop in Doha at best prices. Buy now Blue star AC and General AC from our showroom."
        />
        <link rel="canonical" href="https://acqatar.com/products" />
      </Helmet>
      <div className="w-full h-auto py-20 max-w-screen-2xl">
        <motion.div
          initial="hidden"
          animate={controls}
          variants={animationAc}
          className="md:text-5xl text-4xl flex flex-col font-bold justify-center items-center lg:mb-20 md:mb-16 mb-12 text-white"
        >
          {/* <motion.div
            initial="hidden"
            animate={controls}
            variants={animation1}
            className="h-10 md:w-10 w-8 flex items-center mr-5"
          >
            <div className="h-1 w-full sm:mb-0"></div>
          </motion.div> */}
          <p>PRODUCTS</p>
          {/* <motion.div
            initial="hidden"
            animate={controls}
            variants={animation2}
            className="h-10 md:w-10 w-8 flex items-center ml-5"
          >
            <div className="h-1 w-full sm:mb-0"></div>
          </motion.div> */}
          {/* Added content */}
          <div className="mt-4 text-center md:text-3xl text-xl">
            {/* <h1>Split AC and Windows AC in Qatar</h1> */}
            <h1>SPLIT AC AND WINDOWS AC IN QATAR</h1>
          </div>
        </motion.div>

        {/* first section */}
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={pageTransition}
          className="w-full h-auto md:flex mb-25 lg:px-20 md:px-15 px-18"
        >
          <div className="md:w-[35%] h-auto flex flex-col items-start justify-center 2xl:pl-10 xl:text-xl lg:text-base text-xs ">
            {topArray.map((item) => (
              <div key={item.id}>
                <motion.div
                  whileHover={{
                    scale: 1.15,
                    transition: { yoyo: Infinity },
                  }}
                  className="flex items-center text-white font-medium py-1 md:py-2 cursor-pointer"
                  onClick={() => handleOpen(item.id)}
                >
                  <motion.img
                    src={RIGHT}
                    alt=""
                    className={`object-contain mr-2 ${
                      open && id === item.id ? "rotate-90" : "rotate-0"
                    }`}
                    animate={{ rotate: open && id === item.id ? 90 : 0 }}
                    transition={{ duration: 0.3 }}
                  />
                  <p className="font-bold">{item.title}</p>
                </motion.div>

                <AnimatePresence>
                  {open && id === item.id && (
                    <motion.div
                      initial={{
                        opacity: 0,
                        height: 0,
                      }}
                      animate={{
                        opacity: 1,
                        height: "auto",
                      }}
                      exit={{
                        opacity: 0,
                        height: 0,
                        transition: {
                          duration: 0.4,
                          ease: "easeInOut",
                          delay: 0.4,
                        },
                      }}
                      transition={{ duration: 0.6, ease: "easeInOut" }}
                      className="w-full pl-4 pr-4 border-2 border-[#0186EB] rounded-3xl"
                    >
                      <motion.div
                        animate={{
                          height: "auto",
                          paddingTop: "0.5rem",
                          paddingBottom: "0.5rem",
                        }}
                        exit={{
                          height: 0,
                          paddingTop: 0,
                          paddingBottom: 0,
                          transition: {
                            duration: 0.4,
                            ease: "easeInOut",
                          },
                        }}
                        transition={{
                          duration: 0.4,
                          ease: "easeInOut",
                          delay: 0.4, // Ensure the padding animation starts after the background
                        }}
                      >
                        <motion.p
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{
                            opacity: 0,
                            transition: {
                              duration: 0.4,
                              ease: "easeInOut",
                            },
                          }}
                          transition={{
                            duration: 0.4,
                            ease: "easeInOut",
                            delay: 0.4, // Ensure the text fades out after the background
                          }}
                          className="text-white text-sm lg:text-lg"
                        >
                          {item.dsp}
                        </motion.p>
                      </motion.div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ))}
          </div>
          <div className="md:w-[65%] h-auto flex items-center justify-center mt-[20%] md:-mt-0">
            <img
              src={FIRST}
              alt="Best split ac and windows ac shop in Doha"
              className="lg:w-[60%] w-[70%]"
            />
          </div>
        </motion.div>

        {/* second section */}

        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={pageTransition}
          className="w-full h-auto md:flex px-20 mb-25 flex flex-col-reverse md:flex-row "
        >
          <div className="md:w-[70%] h-auto flex items-center mt-[20%] md:-mt-0">
            <img
              src={SECOND}
              alt="AC Shop in Qatar"
              className="object-contain"
            />
          </div>

          <div className=" md:w-[40%] h-auto flex flex-col items-start justify-center 2xl:px-10 xl:text-xl lg:text-base text-xs ">
            {topArray1.map((item) => (
              <div key={item.id}>
                <motion.div
                  whileHover={{
                    scale: 1.15,
                    transition: { yoyo: Infinity },
                  }}
                  className="flex items-center text-white font-medium py-1 md:py-2 cursor-pointer"
                  onClick={() => handleOpen(item.id)}
                >
                  <motion.img
                    src={RIGHT}
                    alt=""
                    className={`object-contain mr-2 ${
                      open && id === item.id ? "rotate-90" : "rotate-0"
                    }`}
                    animate={{ rotate: open && id === item.id ? 90 : 0 }}
                    transition={{ duration: 0.3 }}
                  />
                  <p className="font-bold">{item.title}</p>
                </motion.div>

                <AnimatePresence>
                  {open && id === item.id && (
                    <motion.div
                      initial={{
                        opacity: 0,
                        height: 0,
                      }}
                      animate={{
                        opacity: 1,
                        height: "auto",
                      }}
                      exit={{
                        opacity: 0,
                        height: 0,
                        transition: {
                          duration: 0.4,
                          ease: "easeInOut",
                          delay: 0.4,
                        },
                      }}
                      transition={{ duration: 0.6, ease: "easeInOut" }}
                      className="w-full pl-4 pr-4 border-2 border-[#0186EB] rounded-3xl"
                    >
                      <motion.div
                        animate={{
                          height: "auto",
                          paddingTop: "0.5rem",
                          paddingBottom: "0.5rem",
                        }}
                        exit={{
                          height: 0,
                          paddingTop: 0,
                          paddingBottom: 0,
                          transition: {
                            duration: 0.4,
                            ease: "easeInOut",
                          },
                        }}
                        transition={{
                          duration: 0.4,
                          ease: "easeInOut",
                          delay: 0.4, // Ensure the padding animation starts after the background
                        }}
                      >
                        <motion.p
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{
                            opacity: 0,
                            transition: {
                              duration: 0.4,
                              ease: "easeInOut",
                            },
                          }}
                          transition={{
                            duration: 0.4,
                            ease: "easeInOut",
                            delay: 0.4, // Ensure the text fades out after the background
                          }}
                          className="text-white text-sm lg:text-lg"
                        >
                          {item.dsp}
                        </motion.p>
                      </motion.div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ))}
          </div>
        </motion.div>

        {/* Third section */}

        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants2}
          transition={pageTransition}
          className=" w-full h-auto md:flex px-20"
        >
          <div className="md:w-[35%] h-auto flex flex-col items-start justify-center 2xl:px-10 xl:text-xl lg:text-base text-xs">
            {topArray2.map((item) => (
              <div key={item.id}>
                <motion.div
                  whileHover={{
                    scale: 1.15,
                    transition: { yoyo: Infinity },
                  }}
                  className="flex items-center text-white font-medium py-1 md:py-2 cursor-pointer"
                  onClick={() => handleOpen(item.id)}
                >
                  <motion.img
                    src={RIGHT}
                    alt=""
                    className={`object-contain mr-2 ${
                      open && id === item.id ? "rotate-90" : "rotate-0"
                    }`}
                    animate={{ rotate: open && id === item.id ? 90 : 0 }}
                    transition={{ duration: 0.3 }}
                  />
                  <p className="font-bold">{item.title}</p>
                </motion.div>

                <AnimatePresence>
                  {open && id === item.id && (
                    <motion.div
                      initial={{
                        opacity: 0,
                        height: 0,
                      }}
                      animate={{
                        opacity: 1,
                        height: "auto",
                      }}
                      exit={{
                        opacity: 0,
                        height: 0,
                        transition: {
                          duration: 0.4,
                          ease: "easeInOut",
                          delay: 0.4,
                        },
                      }}
                      transition={{ duration: 0.6, ease: "easeInOut" }}
                      className="w-full pl-4 pr-4 border-2 border-[#0186EB] rounded-3xl"
                    >
                      <motion.div
                        animate={{
                          height: "auto",
                          paddingTop: "0.5rem",
                          paddingBottom: "0.5rem",
                        }}
                        exit={{
                          height: 0,
                          paddingTop: 0,
                          paddingBottom: 0,
                          transition: {
                            duration: 0.4,
                            ease: "easeInOut",
                          },
                        }}
                        transition={{
                          duration: 0.4,
                          ease: "easeInOut",
                          delay: 0.4, // Ensure the padding animation starts after the background
                        }}
                      >
                        <motion.p
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{
                            opacity: 0,
                            transition: {
                              duration: 0.4,
                              ease: "easeInOut",
                            },
                          }}
                          transition={{
                            duration: 0.4,
                            ease: "easeInOut",
                            delay: 0.4, // Ensure the text fades out after the background
                          }}
                          className="text-white text-sm lg:text-lg"
                        >
                          {item.dsp}
                        </motion.p>
                      </motion.div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ))}
          </div>
          <div className="md:w-[70%] flex items-center mt-[20%] md:-mt-0 ">
            <img
              src={THIRD}
              alt="Air coolers in Qatar"
              className="object-conatin"
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
}
