import React from "react";
import { Helmet } from "react-helmet-async";

export default function DynamicHelmet({ title, desp, lin }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={desp} />
      <link rel="canonical" href={lin} />
    </Helmet>
  );
}
