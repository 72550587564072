import React, { useEffect } from "react";
// import HERO from "../../asset/herobg.png";
import AC1 from "../../asset/1ac.png"
import AC2 from "../../asset/2ac.png";
import AC3 from "../../asset/3ac.png";
import AC4 from "../../asset/4ac.png";
import AC5 from "../../asset/5ac.png";
import AC6 from "../../asset/6ac.png";
import AC7 from "../../asset/7ac.png";
import AC8 from "../../asset/8ac.png";
import AC9 from "../../asset/9ac.png";
import AC10 from "../../asset/10ac.png";
import AC11 from "../../asset/11ac.png";
import AC12 from "../../asset/12ac.png";

import HAIER from "../../asset/brands/Haier.png";
import DAIKIN from "../../asset/brands/dai.png";
import MITSUBISHI from "../../asset/brands/m2.png";
import SAMSUNG from "../../asset/brands/sam2.png";
import PANASONIC from "../../asset/brands/pan.png";
import GREE from "../../asset/brands/g2.png";
import BEKO from "../../asset/brands/bekkk.png";
import HISENSE from "../../asset/brands/Hisense.png";
import GENERAL from "../../asset/brands/ge2.png";
import LG from "../../asset/brands/lg.png";
import TOSHIBA from "../../asset/brands/tosh.png";
import MIDEA from "../../asset/brands/midea.png";
import TCL from "../../asset/brands/tcl.png";
import HOMMER from "../../asset/brands/hommer.png";
// import IMG2 from "../../asset/servicetest2.png";
import IMG2 from "../../asset/Family1a.png";
// import IMG2 from "../../asset/Family.png";
// import CP from "../../asset/couple.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCube, Pagination } from "swiper/modules";
export default function HeroPage() {
  const brands = [
    {
      id: 1,
      image: HAIER,
    },
    {
      id: 2,
      image: SAMSUNG,
    },
    {
      id: 3,
      image: HISENSE,
    },
    {
      id: 4,
      image: TOSHIBA,
    },
    {
      id: 5,
      image: LG,
    },
    {
      id: 6,
      image: PANASONIC,
    },
    {
      id: 7,
      image: BEKO,
    },
    {
      id: 8,
      image: GREE,
    },
    {
      id: 9,
      image: GENERAL,
    },
    {
      id: 10,
      image: MIDEA,
    },
    {
      id: 11,
      image: MITSUBISHI,
    },
    {
      id: 12,
      image: TCL,
    },
    {
      id: 13,
      image: DAIKIN,
    },
    {
      id: 14,
      image: HOMMER,
    },
  ];
  const heroAc = [
    {
      id: 1,
      img: AC1, //haier
      alt: "Haier ac showroom in Qatar"
    },
    {
      id: 2,
      img: AC2, //daikin
      alt: "Ac in Qatar"
    },
    {
      id: 3,
      img: AC3, //samsung
      alt: "Ac in Qatar"
    },
    {
      id: 4,
      img: AC4, //panasonic
      alt: "Haier ac showroom in Qatar"
    },
    {
      id: 5,
      img: AC5, //gree
      alt: "Ac in Qatar"
    },
    {
      id: 6,
      img: AC6, //mitsubishi
      alt: "Mitsubishi ac showroom in Qatar"
    },
    {
      id: 7,
      img: AC7, //general
      alt: "Top branded ac showroom in Qatar"
    },
    {
      id: 8,
      img: AC8, //tcl
      alt: "Ac in Qatar"
    },
    {
      id: 9,
      img: AC9, //hommer
      alt: "Ac in Qatar"
    },
    {
      id: 10,
      img: AC10, // toshiba
      alt: "Top branded ac showroom in Doha"
    },
    {
      id: 11,
      img: AC11, //midea
      alt: "Midea branded ac showroom in Qatar"
    },
    {
      id: 12,
      img: AC12, //hisense
      alt: "Hisense AC Showroom in Qatar"
    },
  ];
  // const animationImg = {
  //   hidden: { opacity: 0, x: -3000 },
  //   visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  // };
  const controls = useAnimation();
  const [heroRef, inHero] = useInView();
  useEffect(() => {
    if (inHero) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inHero]);
  return (
    <div className="w-full h-auto relative bg-black-1" ref={heroRef}>
      {/* <div className=" bg-green bg-dark lg:bg-transparent w-full h-[50%] lg:h-full lg:top-0 z-1"> */}
      {/* <div className="bg-blue w-full h-full flex flex-col items-center justify-center px-5 lg:px-25"> */}
      <div className="w-full h-auto flex flex-col items-center justify-end px-5 lg:px-25">
        {/* <div className="bg-yellow text-white h-auto w-full max-w-screen-2xl relative"> */}
        <div className="text-white h-full w-full max-w-screen-2xl relative ">
          <div className="2xl:h-[65vh] xl:h-[56vh] flex mt-5 lg:mt-0">
            {/* <div className="bg-yellow absolute right-0 bottom-[25%] lg:bottom-[0%] w-auto grid grid-cols-1"> */}

            {/* <div className="w-[40%] lg:w-[35%] h-auto mb-20 md:mb-80 lg:mb-100"> */}
            <div className="w-[40%] lg:w-[35%] h-auto ">
              <Swiper
                effect={"cube"}
                grabCursor={true}
                cubeEffect={{
                  shadow: true,
                  slideShadows: false,
                  shadowOffset: 20,
                  shadowScale: 0.94,
                }}
                pagination={false}
                loop={true}
                autoplay={{ delay: 3000 }}
                speed={2000}
                modules={[EffectCube, Pagination, Autoplay]}
                className="w-full"
              >
                {heroAc.map((data) => (
                  <SwiperSlide className="h-full" key={data.id}>
                    <img
                      src={data.img}
                      alt={data.alt}
                      className="w-[100%] object-contain"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div
              className="w-full flex items-center justify-end">
              <img
                src={IMG2}
                alt="AC showroom in Qatar"
                className="object-contain md:w-[60%] md:mt-40 mt-[50%] mb-[20%] md:mb-0 "
              // className="w-[60%] mr-30 mt-15"
              // className="2xl:w-[45%] xl:w-[38%] object-cover mr-20 mt-25"
              // className="w-[10%] lg:w-[40%] lg:bottom-[12%] lg:right-[10%]"
              />
            </div>

          </div>
          <div className="w-full">
            {/* <div className="mb-[1%]"><h1 className="text-lg sm:text-4xl font-bold">BEST AIR CONDITIONING SHOWROOM AND<br/>AC SERVICES IN QATAR</h1></div> */}

            <motion.h1
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1 }}
              className="text-lg sm:text-4xl font-normal"
            >
              GET ALL THE LEADING{" "}
            </motion.h1>
            <motion.h1
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1 }}
              className="text-lg sm:text-4xl font-bold"
            >
              AC BRANDS IN ONE PLACE
            </motion.h1>
            {/* <div><h1 className="text-lg sm:text-2xl font-normal">AC Qatar , We are dedicated to providing top-quality air conditioning solutions for homes and businesses across Qatar. And we offer a comprehensive range of AC services maintenance in Qatar to ensure your comfort all year round.</h1></div> */}
          </div>

          <div className="w-full h-auto rounded-xl bg-white max-w-screen-2xl px-5 mb-14">
            <Swiper
              // slidesPerView={9}
              breakpoints={{
                576: {
                  slidesPerView: 4,
                },
                1000: {
                  slidesPerView: 9,
                },
              }}
              spaceBetween={30}
              centeredSlides={true}
              pagination={false}
              loop={true}
              autoplay={{ delay: 1 }}
              speed={2000}
              modules={[Autoplay]}
              className="w-full "
            >
              {brands.map((data) => (
                <SwiperSlide
                  key={data.id}
                  className="h-auto w-auto flex justify-center"
                >
                  <img
                    src={data.image}
                    alt="brands"
                    className="h-[60px] w-full object-contain md:my-2"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div >
  );
}
