import React from "react";

function NotFound() {
  return (
    <div className="w-full h-full flex justify-center items-center py-8 flex-col">
      <div className="max-w-screen-2xl w-full">
        <img
          className="w-full h-auto object-cover"
          src="https://static-00.iconduck.com/assets.00/404-page-not-found-illustration-2048x998-yjzeuy4v.png"
          alt="404 Not Found"
        />
      </div>
      <div className="w-full h-auto py-2 text-center">
        <p className=" text-[#357C8B] md:text-4xl text-2xl font-bold italic">
          <span className="text-[#68E2FD]">OOPS! </span>
          PAGE NOT FOUND... 
        </p>
      </div>
    </div>
  );
}

export default NotFound;