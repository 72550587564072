import React from "react";
import HeroPage from "../../components/hero/HeroPage";
import Intro from "../../components/intro/Intro";
import Services from "../../components/services/Services";
import Contact from "../../components/contact/Contact";
import Footer from "../../components/footer/Footer";
import DynamicHelmet from "../../utils/svc/DynamicHelmet";
// import Products from "../../components/products/Products";
// import Intro from "../../components/intro/Intro";
// import Service from "../../components/service/Service";
// import Known from "../../components/known/Known";
// import Brands from "../../components/brands/Brands";
// import Contact from "../../components/contact/Contact";

export default function Home() {
  return (
    <div>
      <DynamicHelmet
        title="AC showroom in Qatar | Top ac services in Doha | AC Qatar"
        desp="Top ac showroom in Qatar. Find the best AC services in Qatar, dedicated to providing high-quality maintenance and repairing for your air conditioners."
        lin="https://acqatar.com"
      />
      <div>
        <HeroPage />
      </div>
      <div>
        <Intro />
      </div>
      <div>
        <Services />
      </div>
      <div id="con">
        <Contact />
      </div>
      {/* <div id="services">
        <Footer />
      </div> */}
    </div>
  );
}
