// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import { motion } from 'framer-motion';
// import { CLN, EVAL, HAC, INN, INS, MAN, REP, TRB, VEN, WAT } from '../../utils/svc/Ins';

// export default function ServicePage() {
//     const routeParams = useParams();
//     const [data, setData] = useState(null);
//     const [animationComplete, setAnimationComplete] = useState(false);

//     useEffect(() => {
//         const timer = setTimeout(() => {
//             setAnimationComplete(true);
//         }, 2000); // Duration of the animation in milliseconds
//         return () => clearTimeout(timer);
//     }, []);

//     const pageVariants = {
//         initial: {
//             opacity: 0,
//             y: 50,
//         },
//         in: {
//             opacity: 1,
//             y: 0,
//         },
//         out: {
//             opacity: 0,
//             y: -50,
//         },
//     };

//     const pageTransition = {
//         type: "spring",
//         stiffness: 50,
//         damping: 20,
//     };

//     useEffect(() => {
//         switch(routeParams.type) {
//             case "insulation":
//                 setData(INS);
//                 break;
//             case "installation":
//                 setData(INN);
//                 break;
//             case "evaluation":
//                 setData(EVAL);
//                 break;
//             case "ducting":
//                 setData(HAC);
//                 break;
//             case "water":
//                 setData(WAT);
//                 break;
//             case "repair":
//                 setData(REP);
//                 break;
//             case "maintenance":
//                 setData(MAN);
//                 break;
//             case "troubleshooting":
//                 setData(TRB);
//                 break;
//             case "cleaning":
//                 setData(CLN);
//                 break;
//             case "ventilation":
//                 setData(VEN);
//                 break;
//             default:
//                 setData(null);
//         }
//     }, [routeParams]);

//     return (
//         <motion.div
//             initial="initial"
//             animate="in"
//             exit="out"
//             variants={pageVariants}
//             transition={pageTransition}
//             className='h-full font-rale bg-dark'
//         >
//             <div className='h-auto bg-dark flex justify-center items-center lg:pb-15 md:pb-8 md:pt-10 pt-6 pb-6 '>
//                 {data === null ? (
//                     <p className='text-white'>
//                         No Data
//                     </p>
//                 ) : (
//                     <div className='h-auto w-[80%] mt-10 mb-10 grid lg:grid-cols-2 lg:gap-30 mg:grid-cols-1 md:gap-20 gap-15 max-w-screen-2xl'>
//                         <div className='bg-black relative'>
//                             <img className='object-cover w-full h-full' src={data.img} alt="" />
//                         </div>
//                         <div className='bg-dark h-auto'>
//                             <div className=' h-10  w-full flex gap-4 '>
//                                 <p className='sm:text-2xl font-semibold text-white'>{data.title}</p>
//                                 <div className=' h-10 w-10 flex items-center '>
//                                     <div className='bg-primary h-1 w-full mb-4 sm:mb-0'></div>
//                                 </div>
//                             </div>
//                             <div className='h-auto flex justify-center items-center xl:mt-6 lg:mt-6 md:mt-4'>
//                                 <div className='h-full w-full '>
//                                     <p className='text-white font-medium text-justify'>
//                                         {data.desp}
//                                     </p>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 )}
//             </div>
//         </motion.div>
//     );
// }

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import {
  CLN,
  EVAL,
  HAC,
  INN,
  INS,
  MAN,
  REP,
  TRB,
  VEN,
  WAT,
} from "../../utils/svc/Ins";

export default function ServicePage() {
  const routeParams = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    switch (routeParams.type) {
      case "insulation":
        setData(INS);
        break;
      case "installation":
        setData(INN);
        break;
      case "evaluation":
        setData(EVAL);
        break;
      case "ducting":
        setData(HAC);
        break;
      case "water":
        setData(WAT);
        break;
      case "repair":
        setData(REP);
        break;
      case "maintenance":
        setData(MAN);
        break;
      case "troubleshooting":
        setData(TRB);
        break;
      case "cleaning":
        setData(CLN);
        break;
      case "ventilation":
        setData(VEN);
        break;
      default:
        setData(null);
    }
  }, [routeParams]);

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.7,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.95,
      transition: {
        duration: 2,
      },
    },
  };

  // const sentence = {
  //     hidden: { opacity: 1 },
  //     visible: {
  //         opacity: 1,
  //         transition: {
  //             delay: 0.5
  //             ,
  //             staggerChildren: 0.08
  //         },
  //     },
  // }
  // const letter={
  //     hidden:{
  //         opacity:0,
  //         y:50
  //     },
  //     visible:{
  //         opacity:1,
  //         y:0,
  //     },
  // }

  // const pageVariants = {
  //     initial: {
  //         opacity: 0,
  //         scale: 0.95
  //     },
  //     animate: {
  //         opacity: 1,
  //         scale: 1,
  //         transition: {
  //             duration: 0.5
  //         }
  //     },
  //     exit: {
  //         opacity: 0,
  //         scale: 0.95,
  //         transition: {
  //             duration: 2
  //         }
  //     }
  // };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageVariants}
      className="h-full font-rale"
    >
      <div className="h-auto bg-black-1 flex justify-center items-center lg:pb-15 md:pb-8 md:pt-10 pt-6 pb-6 ">
        {data === null ? (
          <p className="text-white">No Data</p>
        ) : (
          <div className=" h-auto w-[80%] mt-10 mb-10 grid lg:grid-cols-2 lg:gap-30 mg:grid-cols-1 md:gap-20 gap-15 max-w-screen-2xl">
            <div className="relative">
              <img
                className="object-contain w-full h-full"
                src={data.img}
                alt={data.alt}
              />
            </div>
            <div className="h-auto">
              <div className=" h-10  w-full flex gap-4 ">
                <p className="sm:text-2xl font-semibold text-white">
                  {data.title}
                </p>
                <div className=" h-10 w-10 flex items-center ">
                  <div className="bg-primary h-1 w-full mb-4 sm:mb-0"></div>
                </div>
              </div>
              <div className="h-auto flex justify-center items-center xl:mt-6 lg:mt-6 md:mt-4">
                <div className="h-full w-full ">
                  <p className="text-white font-medium text-justify">
                    {data.desp}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
}
