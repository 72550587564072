import React, { useEffect } from "react";
import INTRO1 from "../../asset/intro1.png";
import INTRO2 from "../../asset/intro2.png";
import INTRO3 from "../../asset/intro3.png";
// import P1 from "../../asset/ac6.png";
import P1 from "../../asset/exhouse.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import P2 from "../../asset/ac4.png";
import P3 from "../../asset/ac1.png";
import P4 from "../../asset/floor3.png";
import P5 from "../../asset/ac2.png";
import P6 from "../../asset/ac3.png";
import "./Intro.css";
// import P1 from "../../asset/exhaust.png";
// import P2 from "../../asset/p2.png";
// import P3 from "../../asset/p3.png";
// import P4 from "../../asset/p4.png";
// import P5 from "../../asset/p5.png";
// import P6 from "../../asset/p6.png";
export default function Intro() {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const [secondRef, inSecond] = useInView();
  const [thirdRef, inThird] = useInView();

  const animationVariants = {
    hidden: { opacity: 0, scale: 0.5, x: 1000 },
    visible: { opacity: 1, scale: 1, x: 0, transition: { duration: 1 } },
  };

  const animationVariantsTxt = {
    textHide: { opacity: 0, y: -200 },
    textShow: { opacity: 1, y: 0, transition: { duration: 2 } },
  };
  const animationVariantsTxt2 = {
    textHide2: { opacity: 0, y: -200 },
    textShow2: { opacity: 1, y: 0, transition: { duration: 1 } },
  };
  const animationVariantsLeft = {
    leftHide: { opacity: 0, scale: 0.5, x: -1000 },
    leftShow: { opacity: 1, scale: 1, x: 0, transition: { duration: 1 } },
  };
  const animationAc1 = {
    ac1Hide: { opacity: 0, y: 150 },
    ac1Show: { opacity: 1, y: 0, transition: { duration: 1, delay: 0 } },
  };
  const animationAc2 = {
    ac2Hide: { opacity: 0, y: 150 },
    ac2Show: {
      opacity: 1,
      y: 0,
      transition: { duration: 1, delay: 0.25 },
    },
  };
  const animationAc3 = {
    ac3Hide: { opacity: 0, y: 150 },
    ac3Show: {
      opacity: 1,
      y: 0,
      transition: { duration: 1, delay: 0.5 },
    },
  };
  const animationAc4 = {
    ac4Hide: { opacity: 0, y: 150 },
    ac4Show: {
      opacity: 1,
      y: 0,
      transition: { duration: 1, delay: 0.75 },
    },
  };
  const animationAc5 = {
    ac5Hide: { opacity: 0, y: 150 },
    ac5Show: {
      opacity: 1,
      y: 0,
      transition: { duration: 1, delay: 1 },
    },
  };
  const animationAc6 = {
    ac5Hide: { opacity: 0, y: 150 },
    ac5Show: {
      opacity: 1,
      y: 0,
      transition: { duration: 1, delay: 1.25 },
    },
  };
  // const animationMan = {
  //   initial: { scale: 1 },
  //   ping: {
  //     scale: [1, 1.5, 1],
  //     transition: {
  //       duration: 2,
  //       ease: "easeIn",
  //       times: [0, 0.5, 1]
  //     }
  //   }
  // };
  // useEffect(() => {
  //   controls.start('ping');
  // }, [controls]);
  const obj = {
    id: 1,
    txt: "We are not just a AC",
    txt1: "showroom, we offer high ",
    txt2: "end products and services ",
    txt3: "from all your known brands",
    txt4: "in the HVAC industry.",
  };

  useEffect(() => {
    if (inThird) {
      controls.start("ac1Show");
      controls.start("ac2Show");
      controls.start("ac3Show");
      controls.start("ac4Show");
      controls.start("ac5Show");
    } else {
      controls.start("ac1Hide");
      controls.start("ac2Hide");
      controls.start("ac3Hide");
      controls.start("ac4Hide");
      controls.start("ac5Hide");
    }
  }, [controls, inThird]);
  useEffect(() => {
    if (inView) {
      controls.start("visible");
      controls.start("textShow");
    } else {
      controls.start("textHide");
      controls.start("hidden");
    }
  }, [controls, inView]);

  useEffect(() => {
    if (inSecond) {
      controls.start("leftShow");
      controls.start("textShow2");
    } else {
      controls.start("leftHide");
      controls.start("textHide2");
    }
  }, [controls, inSecond]);

  const array2 = [
    {
      id: 1,
      title: "Window Acs",
      // img: P3,
      img: P3,
    },
    {
      id: 2,
      title: "Wall Mounted Acs",
      img: P5,
    },
    {
      id: 3,
      title: "Ductable Acs",
      img: P6,
    },
    {
      id: 4,
      title: "Cassette Acs",
      img: P2,
    },
    {
      id: 5,
      title: "Floor Stand Acs",
      img: P4,
    },
    {
      id: 6,
      title: "House Exhaust Fan",
      img: P1,
    },
  ];

  const getAnimateVarients = (index) => {
    if (index === 0) return animationAc1;
    else if (index === 1) return animationAc2;
    else if (index === 2) return animationAc3;
    else if (index === 3) return animationAc4;
    else if (index === 4) return animationAc5;
    else if (index === 5) return animationAc6;
    else return animationAc1;
  };
  return (
    <div className="w-full h-auto bg-black-1 relative pt-[10%] md:pt-[6%] xl:pt-0 overflow-x-hidden">
      {/* //top session start// */}
      <div
        className="relative flex flex-col lg:flex-row justify-center items-center h-auto "
        ref={ref}
      >
        <div className="relative lg:absolute w-full h-full px-5 lg:px-25 flex justify-center items-center">
          <div className="w-full grid grid-cols-1 lg:grid-cols-2 max-w-screen-2xl">
            <div className="w-full h-auto text-center lg:text-start">
              <div className="mb-5">
                <motion.p
                  animate={controls}
                  variants={animationVariantsTxt}
                  className="w-auto h-auto text-white text-4xl lg:text-4xl font-bold"
                >
                  Top ac showroom in Qatar and find the best AC services in
                  Doha, Qatar.
                </motion.p>
              </div>
              <div className="">
                <motion.p
                  animate={controls}
                  variants={animationVariantsTxt}
                  className="w-auto h-auto text-gray-2 xl:text-xl font-normal"
                >
                  {/* From all the leading brands in the HVAC industry, AC Qatar offers comprehensive solutions. Whether you need duct work, FCU, AHU, split AC, central AC, cassette AC, chilled water systems, water tank cooling, or chiller maintenance, our highly skilled and experienced in-house team is here to meet all your requirements.For air conditioning, AC sales, service, maintenance, offers, annual maintenance contracts, and heating and cooling services in Qatar, AC Qatar is your premier solution. We cover all your needs from AC repair to comprehensive maintenance packages. */}
                  From all the leading brands in the HVAC industry, AC Qatar
                  offers comprehensive solutions. Whether you need duct work,
                  FCU, AHU, split AC, central AC, cassette AC, chilled water
                  systems, water tank cooling, or chiller maintenance, our
                  highly skilled and experienced in-house team is here to meet
                  all your requirements. For air conditioning, AC sales,
                  service, maintenance, offers, annual maintenance contracts,
                  and heating and cooling services in Qatar, AC Qatar is your
                  premier solution. We cover all your needs from AC repair to
                  comprehensive maintenance packages. AC Qatar is dedicated to
                  providing top-quality air conditioning solutions for homes and
                  businesses across Qatar. We offer a comprehensive range of AC
                  services and maintenance, including installation, repair, and
                  regular upkeep, to ensure your comfort all year round.
                  {/* AC Qatar , We are dedicated to providing top-quality air conditioning solutions for homes and businesses across Qatar. And we offer a comprehensive range of AC services maintenance in Qatar to ensure your comfort all year round. */}
                </motion.p>
              </div>
            </div>
            {/* <div className="w-full h-auto text-center lg:text-start">
              <div className="mb-5">
                <motion.p
                  animate={controls}
                  variants={animationVariantsTxt}
                  className="w-auto h-auto text-white text-4xl lg:text-4xl font-bold"
                >
                  At AC Qatar, we specialize in    <br />
                  delivering top-tier products    <br />
                   across multiple brands
                </motion.p>
              </div>
              <div className="">
                <motion.p
                  animate={controls}
                  variants={animationVariantsTxt}
                  className="w-auto h-auto text-gray-2 text-xl font-normal"
                >
                  From all the leading brands in the HVAC industry, AC Qatar offers comprehensive solutions. Whether you need duct work, FCU, AHU, split AC, central AC, cassette AC, chilled water systems, water tank cooling, or chiller maintenance, our highly skilled and experienced in-house team is here to meet all your requirements.For air conditioning, AC sales, service, maintenance, offers, annual maintenance contracts, and heating and cooling services in Qatar, AC Qatar is your premier solution. We cover all your needs from AC repair to comprehensive maintenance packages.
                </motion.p>
              </div>
            </div> */}
          </div>
        </div>
        <div className=" w-full h-full grid grid-cols-1 lg:grid-cols-2 ">
          <div className="w-full h-full" />
          <motion.div
            initial="hidden"
            animate={controls}
            variants={animationVariants}
            className="w-full h-full"
          >
            <img src={INTRO1} alt="" className="h-auto w-full object-cover " />
          </motion.div>
        </div>
      </div>
      {/* //top session end// */}

      <div className="w-full h-full relative" ref={secondRef}>
        <motion.div
          initial="hidden"
          animate={controls}
          variants={animationVariantsLeft}
          className="absolute bottom-[-30%] left-0 w-[50%]"
        >
          <img
            src={INTRO3}
            alt=""
            className="md:h-[420px] xl:h-[500px] h-[330px] 2xl:h-full object-contain"
          />
        </motion.div>

        <div className="w-full h-full px-5 lg:px-25 flex justify-center items-center">
          <div className="w-full grid grid-cols-10 h-auto max-w-screen-2xl">
            <div className="hidden lg:flex w-full h-auto items-end justify-end col-span-10 lg:col-span-6 ">
              <img
                src={INTRO2}
                alt="user"
                className="h-[300px] 2xl:h-full relative 2xl:right-[-13%]"
              />
            </div>
            <motion.div
              animate={controls}
              variants={animationVariantsTxt2}
              className=" text-white w-full flex flex-col items-center lg:items-start justify-center col-span-10 lg:col-span-4 text-center lg:text-start 2xl:pl-[15%]"
            >
              <p className="xl:text-3xl text-2xl font-bold">{obj.txt}</p>
              <p className="xl:text-3xl text-2xl font-bold">{obj.txt1}</p>
              <p className="xl:text-3xl text-2xl font-bold">{obj.txt2}</p>
              <p className="xl:text-3xl text-2xl font-bold">{obj.txt3}</p>
              <p className="xl:text-3xl text-2xl font-bold">{obj.txt4}</p>
            </motion.div>
            <div className=" lg:hidden w-full flex items-center justify-end  relative col-span-10 ">
              <img
                src={INTRO2}
                alt=""
                className="lg:h-[400px] md:h-[300px] h-[180px] relative"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        ref={thirdRef}
        className="w-full h-auto bg-gray-3 flex flex-col justify-center items-center pt-40 lg:pt-20 xl:pt-40 pb-[3%] px-5 lg:px-25"
      >
        <div className="w-full grid grid-cols-3 lg:grid-cols-6 gap-3 max-w-screen-2xl">
          {array2.map((data, index) => (
            <motion.div
              initial="hidden"
              animate={controls}
              variants={getAnimateVarients(index)}
              key={data.id}
              className=" w-auto h-auto flex flex-col items-center justify-center cursor-pointer"
            >
              <img
                src={data.img}
                alt=""
                className="w-[90%] h-full object-contain"
              />
              <p className="font-bold mt-4 2xl:text-xl xl:text-base text-xs">
                {data.title}
              </p>
            </motion.div>
          ))}
        </div>
        <div className="mt-[8%] max-w-screen-2xl text-center lg:text-start">
          <div>
            <p className="text-4xl font-bold md:mb-[2%] mb-[10%]">
              Are you looking for heating and cooling in Doha?
            </p>
          </div>
          <div>
            <p className="mb-[1%] text-lg">
              Look no further! We offer an extensive range of air conditioning
              units from leading brands, ensuring you find the perfect system to
              meet your specific needs.
            </p>
          </div>
          <div>
            <p className="text-lg">
              When it comes to air conditioning solutions in Qatar, we stand out
              as the premier provider for both AC sales and services. We
              understand the diverse climate conditions in Qatar and the
              importance of a reliable air conditioning system. That’s why we
              only supply top-quality products from trusted manufacturers.
              Whether you need a system for a single room or an entire building,
              we have the perfect solution to meet your cooling needs. Our
              expert team is here to help you choose the right AC unit that fits
              your budget and requirements.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
