import "./App.css";
import React from "react";
import { HelmetProvider } from "react-helmet-async";
import MainLayout from "./layout/MainLayout";
function App() {
  return (
    <div>
      <HelmetProvider>
        <MainLayout />
      </HelmetProvider>
    </div>
  );
}

export default App;
