import React, { useEffect } from "react";
import AC from "../../asset/p5.png";
// import IMG2 from "../../asset/s1.png";
// import LADY from "../../asset/Lady.png";
import DOT from "../../asset/services/w.png";
import DOT1 from "../../asset/services/ac3.png";
import DOT2 from "../../asset/services/c.png";
import DOT3 from "../../asset/services/p.png";
import "./Services.css";
import LADY2 from "../../asset/final2.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { Autoplay, EffectCube, Pagination } from "swiper/modules";
// import { Link } from "react-router-dom";
export default function Services() {
  // const array = [
  //   {
  //     id: 1,
  //     txt: "Ac Qatar Service conducts meticulous site evaluations, offering",
  //     txt2: " tailored project proposals that guarantee optimal cooling",
  //     txt3: "solutions for your space, ensuring ultimate comfort.",
  //   },
  // ];

  const array2 = [
    {
      id: 1,
      title: "AC Installation",
      des: "We are committed to ensuring your comfort with top-quality air conditioning solutions tailored to meet your specific needs.Get in touch with us today for a free consultation or to schedule your AC installation and experience the difference of working with true professionals.",
    },
    {
      id: 2,
      title: "HVAC Ducting ",
      des: "Specialize in providing top-tier HVAC ducting solutions designed to enhance the efficiency and performance of your heating, ventilation, and air conditioning systems. Our expert team ensures precise design, fabrication, and installation of ductwork tailored to meet the unique needs of your residential, commercial, or industrial spaces.",
    },
    {
      id: 3,
      title: "Chilled water systems",
      des: "Our expert team handles everything from system design and installation to maintenance and troubleshooting, ensuring your chilled water system operates flawlessly.",
    },
    {
      id: 4,
      title: "AC Repair and maintenance",
      des: "Regular maintenance from our team ensures your AC operates efficiently, extends its lifespan, and prevents costly breakdowns.",
    },
    {
      id: 5,
      title: "24X7 Support",
      des: "we pride ourselves on offering 24/7 support to address any HVAC issues you may encounter, day or night. Our dedicated team of professionals is available around the clock to provide prompt assistance and ensure your systems continue to operate smoothly.",
    },
    {
      id: 6,
      title: "AC Services",
      des: "Regular maintenance is key to keeping your air conditioning system running smoothly and efficiently. Our AC maintenance services are designed to prevent issues before they arise, saving you time and money in the long run.",
    },
  ];
  const controls = useAnimation();
  const [txtRef, inTxt] = useInView();
  const animationAc2 = {
    textHide: { opacity: 0, y: -100 },
    textShow: { opacity: 1, y: 0, transition: { duration: 1 } },
  };
  const animationVariantsTxt = {
    textHide: { opacity: 0, y: -100 },
    textShow: { opacity: 1, y: 0, transition: { duration: 2 } },
  };

  useEffect(() => {
    if (inTxt) {
      controls.start("textShow");
    } else {
      controls.start("textHide");
    }
  }, [controls, inTxt]);
  return (
    <div
      ref={txtRef}
      // className="bg-red w-full h-auto relative pt-10 md:pt-15 px-5 lg:px-25"
      className="bg-black-1 w-full h-auto flex flex-col items-center justify-center relative pt-10 md:pt-15 px-5 lg:px-25 overflow-x-hidden"
    >
      <div className="w-full h-auto max-w-screen-2xl grid grid-cols-10">
        <div className="w-full col-span-10 h-full relative">
          <img
            src={LADY2}
            alt=""
            className="absolute xl:w-[50%] md:w-[53%] w-[55%] 2xl:bottom-[5%] lg:bottom-[4%] md:bottom-[5%] bottom-[3%] right-0 ml-[10%]"
          />
          <motion.div
            initial="hidden"
            animate={controls}
            variants={animationAc2}
            className="w-[35%] flex items-center justify-start mb-5 lg:mb-15"
          >
            <img src={AC} alt="" className="object-cover w-full h-auto" />
          </motion.div>
          <div className="2xl:w-[45%] xl:w-[50%] h-auto mb-50 md:mb-80 xl:mb-0 2xl:mb-50 p-2">
            <Swiper
              pagination={false}
              loop={true}
              autoplay={{ delay: 2000 }}
              speed={2000}
              modules={[EffectCube, Pagination, Autoplay]}
              className="w-full h-full"
            >
              {array2.map((data) => (
                <SwiperSlide className="h-full" key={data.id}>
                  <div className="text-white w-full h-auto p-[1%]">
                    <p className="text-sm md:text-2xl lg:text-4xl font-bold text-white py-5 h-auto ">
                      {data.title}
                    </p>
                    <p className="text-[5%] text-sm lg:text-lg text-gray-2 font-normal h-auto">
                      {data.des}
                    </p>
                    <div className="mt-[5%]">
                      <a href="#con">
                        <button className="bg-white rounded-full md:py-3 md:px-8 px-3 py-1 md:text-sm text-[8px] font-bold text-dark">
                          <motion.p
                            whileHover={{
                              scale: 1.15,
                              transition: { yoyo: Infinity },
                            }}
                          >
                            Enquire Now
                          </motion.p>
                        </button>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
      <div className="w-full h-auto text-center lg:text-start max-w-screen-2xl py-[10%] lg:py-0">
        <div className="mb-5">
          <motion.p
            animate={controls}
            variants={animationVariantsTxt}
            className="w-auto h-auto text-white text-4xl lg:text-4xl font-bold"
          >
            Why Choose Us?
          </motion.p>
        </div>
        <div className="text-start">
          <motion.div
            animate={controls}
            variants={animationVariantsTxt}
            className="list-item"
          >
            <div className="flex lg:items-center items-start  w-full h-auto gap-5 mb-[2%]">
              <div className="bg-white p-1.5 mt-2 lg:mt-0">
                <img
                  src={DOT}
                  alt=""
                  className="md:w-8 w-16 aspect-square object-contain"
                />
              </div>
              <p className="text-gray-2 md:text-xl text-lg font-normal">
                <span className="text-white mr-1 md:text-2xl text-xl font-bold">
                  Experienced Professionals :{" "}
                </span>
                Our team of highly trained and experienced technicians is
                dedicated to delivering the best service possible.
              </p>
            </div>
            <div className="flex lg:items-center items-start w-full h-auto gap-5 mb-[2%]">
              <div className="bg-white p-1.5 mt-2 lg:mt-0">
                <img
                  src={DOT1}
                  alt=""
                  className="md:w-8 w-16 aspect-square object-fit"
                />
              </div>
              <p className="text-gray-2 md:text-xl text-lg font-normal">
                <span className="text-white mr-1 md:text-2xl text-xl font-bold">
                  Quality Products :{" "}
                </span>
                We supply only the best AC units from trusted brands, ensuring
                you get reliable and efficient cooling solutions.
              </p>
            </div>
            <div className="flex lg:items-center items-start w-full h-auto gap-5 mb-[2%]">
              <div className="bg-white p-1.5 mt-2 lg:mt-0">
                <img
                  src={DOT2}
                  alt=""
                  className="md:w-8 w-16 aspect-square object-fit"
                />
              </div>
              <p className="text-gray-2 md:text-xl text-lg font-normal">
                <span className="text-white mr-1 md:text-2xl text-xl font-bold">
                  Customer Satisfaction :{" "}
                </span>
                We prioritize your comfort and satisfaction, offering
                personalized solutions tailored to your specific needs
              </p>
            </div>
            <div className="flex lg:items-center items-start w-full h-auto gap-5 mb-[2%]">
              <div className="bg-white p-1.5 mt-2 lg:mt-0">
                <img
                  src={DOT3}
                  alt=""
                  className="md:w-8 w-16 aspect-square object-fit"
                />
              </div>
              <p className="text-gray-2 md:text-xl text-lg font-normal">
                <span className="text-white mr-1 md:text-2xl text-xl font-bold">
                  Competitive Pricing :{" "}
                </span>
                Enjoy top-notch AC services at competitive prices, without
                compromising on quality.
              </p>
            </div>
          </motion.div>
        </div>
        {/* <div className="text-start">
          <motion.ol
            animate={controls}
            variants={animationVariantsTxt}
            className="list-item"
          >
            <li className="text-gray-2 text-xl font-normal mb-[2%]">
              <b>
                <span className="text-white mr-1 text-2xl">
                  Experienced Professionals :{" "}
                </span>
              </b>{" "}
              Our team of highly trained and experienced technicians is
              dedicated to delivering the best service possible.
            </li>
            <li className="text-gray-2 text-xl font-normal mb-[2%]">
              <b>
                <span className="text-white mr-1 text-2xl">
                  Quality Products :{" "}
                </span>
              </b>{" "}
              We supply only the best AC units from trusted brands, ensuring you
              get reliable and efficient cooling solutions.
            </li>
            <li className="text-gray-2 text-xl font-normal mb-[2%]">
              <b>
                <span className="text-white mr-1 text-2xl">
                  Customer Satisfaction :{" "}
                </span>
              </b>{" "}
              We prioritize your comfort and satisfaction, offering personalized
              solutions tailored to your specific needs
            </li>
            <li className="text-gray-2 text-xl font-normal">
              <b>
                <span className="text-white mr-1 text-2xl">
                  Competitive Pricing :{" "}
                </span>
              </b>{" "}
              Enjoy top-notch AC services at competitive prices, without
              compromising on quality.
            </li>
          </motion.ol>
        </div> */}
      </div>
    </div>
  );
}
