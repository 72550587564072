import React, { useEffect, useRef, useState } from "react";
// import "../hero/Hero.css"
import MIT from "../../asset/m2.png";
import DAI from "../../asset/dai.png";
import SAM from "../../asset/sam2.png";
import TCL from "../../asset/tcl.png";
import HOMMER from "../../asset/hommer.png";
import PAN from "../../asset/pan2.png";
import TOS from "../../asset/tosh.png";
import GEN from "../../asset/gen.png";
import GREE from "../../asset/g2.png";
import HIS from "../../asset/hisense.jpg";
import MID from "../../asset/midea.png";
import LG from "../../asset/lg.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet-async";

export default function Brands() {
  const controls = useAnimation();
  const [txtRef, inTxt] = useInView();
  const animationTxt = {
    textHide: { opacity: 0, x: 1000 },
    textShow: { opacity: 1, x: 0, transition: { duration: 1 } },
  };
  const animationAc = {
    textHide: { opacity: 0, y: -3000 },
    textShow: { opacity: 1, y: 0, transition: { duration: 1 } },
  };
  const animation1 = {
    textHide: { opacity: 0, x: -3000 },
    textShow: { opacity: 1, x: 0, transition: { duration: 1 } },
  };
  const animation2 = {
    textHide: { opacity: 0, x: 3000 },
    textShow: { opacity: 1, x: 0, transition: { duration: 1 } },
  };

  useEffect(() => {
    if (inTxt) {
      controls.start("textShow");
    } else {
      controls.start("textHide");
    }
  }, [controls, inTxt]);

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 2,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 1,
      },
    },
    exit: {
      opacity: 0,
      scale: 2,
      transition: {
        duration: 1,
      },
    },
  };

  return (
    <div
      ref={txtRef}
      className="w-full h-full bg-black-1 font-rale flex flex-col items-center justify-center"
    >
      <Helmet>
        <title>AC dealer shop in Qatar | Branded AC Shop in Doha </title>
        <meta
          name="description"
          content="AC Dealer in shop in Doha, Qatar.Buy air conditioners online in Qatar at best offer price.Buy Gree Air Conditioners and Samsung ac from top ac dealers in Doha "
        />
        <link rel="canonical" href="https://acqatar.com/brands" />
      </Helmet>
      <div className="max-w-screen-2xl">
        <motion.div
          initial="hidden"
          animate={controls}
          variants={animationAc}
          className="w-full h-auto mb-10"
        >
          <div className="md:text-5xl text-4xl flex font-bold justify-center pt-[3.1%] items-center mb-5 text-white">
            <motion.div
              initial="hidden"
              animate={controls}
              variants={animation1}
              className="h-10 md:w-10 w-8 flex items-center mr-5 "
            >
              <div className="bg-primary h-1 w-full sm:mb-0"></div>
            </motion.div>
            BRANDS
            <motion.div
              initial="hidden"
              animate={controls}
              variants={animation2}
              className="h-10 md:w-10 w-8 flex items-center ml-5"
            >
              <div className="bg-primary h-1 w-full sm:mb-0"></div>
            </motion.div>
          </div>
        </motion.div>
        <motion.div
          initial="initial"
          animate="animate"
          exit="exit"
          variants={pageVariants}
          className="pb-25 w-full h-auto grid grid-cols-2 md:grid-cols-3 md:px-40 lg:grid-cols-4 lg:px-40 xl:grid-cols-4 xl:px-40 gap-5 px-10 "
        >
          <div class="col-span-1 bg-white p-3 rounded-[10px] w-auto h-30 border-2 ">
            <img
              className="object-contain w-full h-full"
              src={MIT}
              alt="Mistubishi ac shop in Doha"
            />
          </div>
          <div class="col-span-1 bg-[#f8f8f9] p-3 rounded-[10px] w-auto h-30 border-2 ">
            <img
              className="object-contain w-full h-full"
              src={DAI}
              alt="Daikin ac shop in Doha"
            />
          </div>
          <div class="col-span-1 bg-white p-3 rounded-[10px] w-auto h-30 border-2 ">
            <img
              className="object-contain w-full h-full"
              src={SAM}
              alt="Samsung ac dealer in Qatar"
            />
          </div>
          <div class="col-span-1  bg-white p-3 rounded-[10px] w-auto h-30 border-2">
            <img
              className="object-contain w-full h-full"
              src={TCL}
              alt="TCL Ac showroom in Qatar"
            />
          </div>
          <div class="col-span-1  bg-white p-3 rounded-[10px] w-auto h-30 border-2 ">
            <img
              className="object-contain w-full h-full"
              src={HOMMER}
              alt="Hommer ac shop Doha"
            />
          </div>
          <div class="col-span-1  bg-white p-3 rounded-[10px] w-auto h-30 border-2 ">
            <img
              className="object-contain w-full h-full"
              src={PAN}
              alt="Panasonic ac shop Doha"
            />
          </div>
          <div class="col-span-1  bg-white p-3 rounded-[10px] w-auto h-30 border-2">
            <img
              className="object-contain w-full h-full"
              src={TOS}
              alt="Toshiba ac shop Doha"
            />
          </div>
          <div class="col-span-1  bg-white p-3 rounded-[10px] w-auto h-30 border-2 ">
            <img
              className="object-contain w-full h-full"
              src={GEN}
              alt="General ac  in Qatar"
            />
          </div>
          <div class="col-span-1  bg-white p-3 rounded-[10px] w-auto h-30 border-2">
            <img
              className="object-contain w-full h-full"
              src={GREE}
              alt="Gree shop in Qatar"
            />
          </div>
          <div class="col-span-1  bg-white p-3 rounded-[10px] w-auto h-30 border-2 ">
            <img
              className="object-contain w-full h-full"
              src={HIS}
              alt="Hisense Air conditioner in Doha"
            />
          </div>
          <div class="col-span-1  bg-white p-3 rounded-[10px] w-auto h-30 border-2 ">
            <img
              className="object-contain w-full h-full"
              src={MID}
              alt="Midea Air conditioner in Doha"
            />
          </div>
          <div class="col-span-1  bg-white p-3 rounded-[10px] w-auto h-30 border-2 ">
            <img
              className="object-contain w-full h-full"
              src={LG}
              alt="LG Air conditioner in Doha"
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
}
