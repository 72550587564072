import React, { useEffect, useRef, useState } from "react";
import "./AboutUs.css";
import { motion } from "framer-motion";
import INTRO1 from "../../asset/intro1test.jpg";
import EXPERT from "../../asset/expert.png";
import SAFE from "../../asset/reliable.png";
import { Helmet } from "react-helmet-async";

export default function AboutUs() {
  const [isHovered, setIsHovered] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  useEffect(() => {
    // Trigger animation when component mounts
    setShouldAnimate(true);

    // Clean up function to prevent animation on unmount
    return () => {
      setShouldAnimate(false);
    };
  }, []);

  // const ref = useRef(null);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const { top, bottom } = ref.current.getBoundingClientRect();
  //     const windowHeight =
  //       window.innerHeight || document.documentElement.clientHeight;

  //     // Check if the component is in the viewport
  //     if (top < windowHeight && bottom >= 0) {
  //       setIsVisible(true); // Trigger animation
  //       window.removeEventListener("scroll", handleScroll); // Remove event listener once animation is triggered
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll); // Clean up event listener on unmount
  //   };
  // }, []);

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.95,
      transition: {
        duration: 5,
      },
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 1,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.95,
      transition: {
        duration: 2,
      },
    },
  };
  return (
    <div className=" bg-black-1 h-auto w-full font-rale flex items-center justify-center md:px-20 px-10">
      <Helmet>
        <title>Ac showroom in Qatar | Top ac shop in Doha | Ac service </title>
        <meta
          name="description"
          content="Ac showroom in Qatar. AC Qatar is leading ac shop in Doha and we are expert in all ac services. Shop now and find the top branded ac from ac shop in Qatar. "
        />
        <link rel="canonical" href="https://acqatar.com/about" />
      </Helmet>
      <div className="max-w-screen-2xl py-10 ">
        <div className="h-full w-full grid lg:grid-cols-2 mg:grid-cols-1 xl:gap-18 lg:gap-12 md:gap-15 gap-5 relative ">
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={pageVariants}
            className="h-auto w-auto"
          >
            <div className="h-full w-auto relative">
              <img className="object-cover w-full h-full" src={INTRO1} alt="" />
              <div className="absolute 2xl:h-65 2xl:w-90 lg:h-40 lg:w-60 md:w-80 md:h-55 w-45 h-30 bg-black right-0 bottom-0 z-1">
                <div className="bg-black h-full">
                  <div className="h-[93%] w-[95%] absolute bottom-0 right-0">
                    <img
                      className="object-cover h-full"
                      src="https://www.shutterstock.com/image-photo/air-conditioner-technician-repair-service-600nw-2304576719.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="absolute h-full md:w-14 w-6 bg-black top-0 right-0">
                <div className="h-full md:w-7 w-3 bg-primary absolute right-0"></div>
              </div>
            </div>
          </motion.div>
          <div
            // ref={ref}
            className="aaa h-auto flex flex-col items-center justify-center"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {/* <div
            // ref={ref}
            className={`h-auto flex flex-col items-center justify-center ${
              isVisible ? "aaa" : " "
            }`}
           
          > */}
            <div className=" h-10 w-full flex items-center justify center gap-4">
              <p className="sm:text-2xl xl:text-3xl lg:text-xl font-semibold text-white">
                {" "}
                OUR INTRODUCTION
              </p>
              <div className=" h-10 w-10 flex items-center ">
                <div className="h-1 w-full md:mt-3 bg-primary sm:mb-0"></div>
              </div>
            </div>
            <div className="h-auto w-full flex items-center justify-center md:py-7 md:my-0 my-6">
              {/* <div
              className={`${
                shouldAnimate ? "aaa" : ""
              } h-auto w-full flex items-center justify-center md:py-7 md:my-0 my-6`}
            > */}
              <div className="h-auto w-full">
                <p className="text-xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-3xl font-bold text-primary mb-6 italic text-center">
                  {" "}
                  Leading AC Showroom in Doha - Premium Shop for Top Air
                  Conditioning Systems & Services.{" "}
                </p>
                <p className="text-xl 2xl:text-3xl xl:text-2xl lg:text-xl md:text-3xl font-bold text-white">
                  Discover Leading Brands, Expert Service, and Total Comfort at
                  Our Multi-Brand AC Emporium
                </p>

                {/* <p className='text-lg 2xl:text-3xl xl:text-2xl lg:text-xl md:text-3xl font-bold text-white text-center'>Discover Leading Brands, Expert Service,</p>
                                <p className='text-lg 2xl:text-3xl xl:text-2xl lg:text-xl md:text-3xl font-bold text-white text-center'>and Total Comfort at Our Multi-Brand</p>
                                <p className='text-lg 2xl:text-3xl xl:text-2xl lg:text-xl md:text-3xl font-bold text-white text-center'>AC Emporium</p> */}
              </div>
            </div>
            <div
              className={`main bg-primary rounded-lg h-1 ${
                isHovered ? "w-full" : "w-[0%]"
              } sm:mb-0 hover:w-full transition-all duration-500`}
            ></div>
            <div className="h-auto relative flex justify-center items-center ">
              <div className="lg:h-full py-3 w-full ">
                {/* <div
                className={`lg:h-full py-3 w-full ${
                  shouldAnimate ? "aaa" : ""
                }`}
              > */}
                <p className="text-white text-xs xl:text-base lg:text-xs md:text-sm">
                  <span className="font-bold">AC Qatar </span>is top ac showroom
                  in Qatar and we are expert in all ac services in Doha, Qatar.
                  Our dedicated team offers a comprehensive range of top-tier
                  products and services, including{" "}
                  <span className="font-semibold">
                    AC sales, installation, maintenance, and repairs
                  </span>
                  . With a focus on quality and customer satisfaction, we ensure
                  your comfort is our top priority. Explore our offerings and
                  experience unparalleled excellence in cooling services in
                  Qatar.
                </p>
                {/* <p className="text-white text-xs xl:text-base lg:text-xs md:text-sm">
                  <span className="font-bold">Welcome to Qatar AC</span>, your
                  premier destination for exceptional HVAC solutions. Our
                  dedicated team offers a comprehensive range of top-tier
                  products and services, including{" "}
                  <span className="font-semibold">
                    AC sales, installation, maintenance, and repairs
                  </span>
                  . With a focus on quality and customer satisfaction, we ensure
                  your comfort is our top priority. Explore our offerings and
                  experience unparalleled excellence in cooling services in
                  Qatar.
                </p> */}

                <p className="mt-1 text-white text-xs xl:text-base lg:text-xs md:text-sm">
                  From{" "}
                  <span className="font-semibold">
                    air conditioning sales, service, and maintenance
                  </span>{" "}
                  to exclusive offers and annual maintenance contracts, Qatar AC
                  provides comprehensive heating and cooling services in Qatar.
                  Your ultimate destination for all your HVAC needs.
                </p>

                <p className="mt-1 text-white text-xs xl:text-base lg:text-xs md:text-sm">
                  We offer expert installation of air conditioners from top
                  brands and its's models like{" "}
                  <span className="font-semibold">
                    TCL, Haier, Toshiba, Samsung, Hisense, LG, Beko, Gree,
                    Midea, General, Daikin
                  </span>{" "}
                  ensuring{" "}
                  <span className="font-semibold">
                    reliable and stable operation
                  </span>{" "}
                  in all conditions.
                </p>

                {/* <p className="mt-1 text-white text-xs xl:text-base lg:text-xs md:text-sm">
                  We guarantee that your air conditioner will be installed
                  flawlessly, ensuring its{" "}
                  <span className="font-semibold">
                    reliable and stable operation
                  </span>{" "}
                  in all conditions.
                </p> */}

                <p className="mt-1 text-white text-xs xl:text-base lg:text-xs md:text-sm">
                  Whether you're{" "}
                  <span className="font-semibold">
                    upgrading or installing a new system
                  </span>
                  , our showroom provides high-quality split AC units with
                  exceptional after-sales service. Experience unmatched{" "}
                  <span className="font-semibold">
                    comfort and reliability with our premium cooling solutions.
                  </span>
                  .
                </p>
              </div>
            </div>
            <div
              className={`main bg-primary rounded-lg h-1 ${
                isHovered ? "w-full" : "w-[0%]"
              } sm:mb-0 hover:w-full transition-all duration-500`}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
