import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
// import LOGO from "../../asset/maninLogoo.png";
import LOGO from "../../asset/logo2.png";
import { motion } from "framer-motion";
import "./Navbar.css"
export const navLink = [
  {
    id: 1,
    title: "Home",
    link: "/",
  },
  {
    id: 2,
    title: "About",
    link: "/about",
  },
  {
    id: 3,
    title: "Products",
    link: "/products",
  },
  {
    id: 4,
    title: "Services",
    link: "/services",
  },
  {
    id: 5,
    title: "Brands",
    link: "/brands",
  },
  {
    id: 6,
    title: "Contact",
    link: "/contact",
  },
];




const Hamburger = ({ open, setOpen }) => {
  return (
    <div
      className="flex relative w-8 h-8 flex-col justify-between items-center lg:hidden z-20"
      onClick={() => {
        setOpen(!open);
      }}
    >
      {/* hamburger button */}
      <span
        className={`h-1 w-8 rounded-lg transform transition duration-300 ease-in-out ${
          open ? "rotate-45 translate-y-3.5 bg-blue" : "bg-white"
        }`}
      />
      <span
        className={`h-1 bg-white rounded-lg transition-all duration-300 ease-in-out ${
          open ? "w-[0px] bg-blue" : "w-8"
        }`}
      />
      <span
        className={`h-1 w-8 rounded-lg transform transition duration-300 ease-in-out ${
          open ? "-rotate-45 -translate-y-3.5 bg-blue" : "bg-white"
        }`}
      />
    </div>
  );
};
function MobileNav({ open, setOpen }) {
  return (
    <div
      className={`absolute top-0 left-0 h-auto w-screen bg-dark transform ${
        open ? "translate-x-0" : "-translate-x-full"
      } transition-transform duration-300 ease-in-out filter drop-shadow-md z-10`}
    >
      <div className="flex items-center justify-between filter drop-shadow-md  h-auto px-10">
        <Link to="/">
          <img
            src={LOGO}
            alt="images"
            className=" object-cover w-[30%] h-full py-5"
          />
        </Link>
      </div>
      <div className="flex flex-col ml-4 mt-5">
        {navLink.map((data) => (
          <Link
            key={data.id}
            to={data.link}
            className="text-xl font-medium my-4 text-center text-white"
            onClick={() =>
              setTimeout(() => {
                setOpen(!open);
              }, 100)
            }
          >
            {data.title}
          </Link>
        ))}
      </div>
    </div>
  );
}

export default function Navbar({ setOpen, open }) {
  const [currentPath, setCurrentPath] = useState('');
  
  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, []);
  return (
    // <nav className="flex filter py-0 h-50 items-center relative justify-center z-10">
    <nav className="w-full flex items-center relative justify-center z-10 px-5 lg:px-25 py-5 bg-black-1">
      <div className="w-full flex items-center max-w-screen-2xl">
        <MobileNav open={open} setOpen={setOpen} />
        <div className="w-full h-auto flex items-center ">
          <Link to="/">
            <motion.div
              whileHover={{ scale: 1.1, textShadow: "0px 0px 4px white" }}
              whileTap={{ scale: 0.9 }}
              initial={{ y: -100, opacity: 0, scale: 0.5 }}
              animate={{ y: 0, opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              className="h-auto w-auto"
            >
              {/* <div className="h-auto w-auto"> */}
              <img
                src={LOGO}
                alt="images"
                className=" object-cover 2xl:w-[30%] xl:w-[50%] lg:w-[80%] w-[30%] h-full"
              />
            </motion.div>
          </Link>
        </div>
        <div className=" flex justify-end items-center">
          <Hamburger open={open} setOpen={setOpen} />

          <div className="hidden lg:flex justify-between items-center w-full">
            <div className="flex justify-start items-center w-auto h-auto ">
              {navLink.map((data) => (
                <motion.a
                  whileHover={{ scale: 1.1, textShadow: "0px 0px 4px white" }}
                  whileTap={{ scale: 0.9 }}
                  initial={{ y: -100, opacity: 0, scale: 0.5 }}
                  animate={{ y: 0, opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                  key={data.id}
                  href={data.link}
                  className={currentPath === data.link ? 'active-link' : ''}
                >
                  <p className="text-white px-5 text-xl opacity-50 hover:opacity-100">
                    {data.title}
                  </p>
                </motion.a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
