import React, { useEffect } from "react";
import "../contact/Contact.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
// import TWITTER from "../../asset/twor.png"
import FACEBOOK from "../../asset/faor.png";
import LINKEDIN from "../../asset/inor.png";
import YTUBE from "../../asset/ytube.png";
import INSTAGRAM from "../../asset/insta2.png";

export default function Contact() {
  const controls = useAnimation();
  const [txtRef, inTxt] = useInView();
  const animationLin = {
    textHide: { opacity: 0, x: 1000 },
    textShow: { opacity: 1, x: 0, transition: { duration: 2 } },
  };
  const animationFac = {
    textHide: { opacity: 0, x: -1000 },
    textShow: { opacity: 1, x: 0, transition: { duration: 2 } },
  };
  const animationYou = {
    textHide: { opacity: 0, y: 10, rotate: 360 },
    textShow: { opacity: 1, y: 0, rotate: 0, transition: { duration: 2 } },
  };
  const animationIns = {
    textHide: { opacity: 0, y: 10, rotate: -360 },
    textShow: { opacity: 1, y: 0, rotate: 0, transition: { duration: 2 } },
  };
  useEffect(() => {
    if (inTxt) {
      controls.start("textShow");
    } else {
      controls.start("textHide");
    }
  }, [controls, inTxt]);

  return (
    <div
      ref={txtRef}
      className="flex flex-col items-center justify-center bg-black-1 w-full h-auto lg:px-25 md:px-15 px-5 lg:pt-[5%] md:pt-0 overflow-x-hidden"
    >
      <div className="w-full rounded-lg max-w-screen-2xl ">
        <div className="flex flex-col items-center justify-center p-10">
          <p className="md:text-lg text-white font-extralight">
            Need new ac or service?
          </p>
          <p className="md:text-4xl text-3xl font-bold text-white text-center">
            GET IN TOUCH
          </p>
        </div>
        <div className="md:flex w-full md:px-20 py-10 text-[#F3F5F7]">
          <div className="md:w-[50%] px-5  ">
            <div className="flex flex-col mb-10">
              <label className="font-extralight">Name</label>
              <input
                id="myInput"
                type="text"
                className="py-2 bg-black-1 outline-none  border-b-[1px] border-[#F3F5F7] text-xl"
              />
            </div>
            <div className="flex flex-col mb-10">
              <label className="font-extralight">Email</label>
              <input
                id="myInput"
                type="email"
                className="py-2 bg-black-1 outline-none  border-b-[1px] border-[#F3F5F7]"
              />
            </div>
            <div className="flex flex-col mb-10">
              <label className="font-extralight">How can i help you</label>
              <select
                name="languages"
                id="lang"
                className="styled-select py-2 bg-black-1 outline-none border-b-[1px] border-[#F3F5F7]"
              >
                <option value="" disabled selected hidden></option>
                <option className="" value="">
                  Business Consultancy
                </option>
                <option value="">Digital</option>
                <option value="">Services</option>
              </select>
            </div>
          </div>
          <div className="md:w-[50%] px-5">
            <div className="flex flex-col mb-10">
              <label className="font-extralight">Mobile</label>
              <input
                id="myInput"
                type="text"
                className="py-2.5 bg-black-1 outline-none  border-b-[1px] border-[#F3F5F7]"
              />
            </div>
            <div className="flex flex-col mb-10">
              <label className="font-extralight">Select Nationality</label>
              <select
                name="nationality"
                id="nation"
                className="styled-select py-2 bg-black-1 outline-none border-b-[1px] border-[#F3F5F7]"
              >
                <option value="" disabled selected hidden></option>
                <option value="">Qatar</option>
                <option value="">America</option>
                <option value="">Japan</option>
                <option value="">India</option>
              </select>
            </div>

            <div className="flex flex-col mb-10">
              <label className="font-extralight">Message</label>
              <input
                id="myInput"
                type="text"
                className="py-2 bg-black-1 outline-none border-b-[1px] border-[#F3F5F7]"
              />
            </div>
          </div>
        </div>
        <div className="h-auto flex items-center justify-center pb-[3%] lg:pb-20">
          <motion.button
            whileHover={{
              scale: 1.2,
              transition: { yoyo: Infinity },
            }}
            className="w-auto bg-white text-sm py-2 px-20 font-bold text-[#000000] rounded-full"
          >
            SUBMIT
          </motion.button>
        </div>
      </div>
      <div className="w-full flex items-center justify-center py-15">
        <motion.a
          initial="hidden"
          animate={controls}
          variants={animationLin}
          href="https://www.linkedin.com/company/ac-qatar/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="w-auto h-auto mr-7">
            <img src={LINKEDIN} alt="" className="w-auto h-auto" />
          </div>
        </motion.a>
        <motion.a
          initial="hidden"
          animate={controls}
          variants={animationIns}
          href="https://www.instagram.com/acqatar_official/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="w-auto h-auto mr-5">
            <img src={INSTAGRAM} alt="" className="w-auto h-auto" />
          </div>
        </motion.a>
        <motion.a
          initial="hidden"
          animate={controls}
          variants={animationYou}
          href="https://www.youtube.com/channel/UC7gh10LMX9Fq8dGrNpp3oBA"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="w-auto h-auto mr-4">
            <img src={YTUBE} alt="" className="w-auto h-auto" />
          </div>
        </motion.a>
        <motion.a
          animate={controls}
          variants={animationFac}
          href="https://www.facebook.com/acqatarofficial"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="w-auto h-auto ">
            <img src={FACEBOOK} alt="" className="w-auto h-auto" />
          </div>
        </motion.a>
      </div>
      {/* <div className='w-auto p-[3%] max-w-screen-2xl bg-green'>
                <iframe className="rounded-lg"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230886.48616859308!2d51.20050406760987!3d25.28399263418422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c534ffdce87f%3A0x44d9319f78cfd4b1!2sDoha%2C%20Qatar!5e0!3m2!1sen!2sin!4v1721732437434!5m2!1sen!2sin" width="600" height="350" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div> */}
    </div>
  );
}
